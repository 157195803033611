<script>
  import { mapActions, mapGetters } from "vuex";

  export default {
    components: {
      Card: () => import("./CarList.vue"),
    },
    computed: {
      ...mapGetters("competitions", ["getCompetitions"]),
    },
    methods: {
      ...mapActions("competitions", ["getCompetitionsDb"]),
      editCompetition(id) {
        this.$router.push({ name: "EditCompetition", params: { id } });
      },
    },
    async mounted() {
      await this.getCompetitionsDb();
    },
  };
</script>

<template>
  <div class="contetViewManagment">
    <Card v-for="(competition, index) in getCompetitions" :key="index" :competition="competition" @click.native="() => editCompetition(competition._id)" />
  </div>
</template>

<style lang="scss">
  .contetViewManagment {
    @include Flex(column);
    width: 100%;
    gap: 20px;
  }
</style>
